<template>
  <div class="constructs view">
    <app-header :title="__('view.ingame.constructs.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="organizationId"
            :endpoint="'/ingame/civilization/organization'"
            :nothing-selected-message="__('view.ingame.constructs.filter_by_organizations')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="constructs"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
          @copyCoordinates="copyCooardinates"
          @deleteRecord="deleteRecord"
          @showChangeLog="showConstructLogs"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <input type="text" id="coordinates_clipboard" style="display: none"/>
      <construct-logs ref="constructLogs"/>
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import convertPos from '../../library/convertPos';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import ConstructLogs from "../../components/Ingame/ConstructLogs";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      ConstructLogs,
      AppHeader,
      DataTable
    },
    data: () => ({
      loading: false,
      constructs: [],
      organizationId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      organization() {
        this.pagination.page = 1;
      },
    },
    methods: {
      showConstructLogs({id}) {
        this.$refs.constructLogs.showDialog(id);
      },
      copyCooardinates(construct) {
        const copyText = document.getElementById('coordinates_clipboard');
        const posString = convertPos.getPosString(construct.position);
        copyText.style.display = 'block';
        copyText.value = posString;
        copyText.select();
        document.execCommand("copy");
        copyText.style.display = 'none';
        this.showSuccessNotification(posString);
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh(callback) {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.organizationId !== null) {
          params.organizationId = this.organizationId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/civilization/construct', { params }).then(async (res) => {
          this.constructs = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.constructs_loaded'));
          if (typeof callback === 'function') {
            callback();
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const ownerIds = this.constructs.map(construct => construct.ownerId);
        if (ownerIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/player/(${ownerIds.join(',')})`, {
            params: {
              fields: 'id,name,organizationMemberships'
            },
          }).then((res) => {
            this.constructs = this.constructs.map(record => {
              record.owner = res.data.find(resRecord => resRecord.id === record.ownerId);
              return record;
            });
            this.showInfoNotification('Owners have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        const orgIds = [];
        this.constructs.forEach(construct => {
          if ('organizationMemberships' in construct.owner) {
            orgIds.push(...construct.owner.organizationMemberships.map(membership => membership.organizationId));
          }
        });
        let organizations = [];
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            organizations = res.data;
            this.showInfoNotification('Parents have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
          this.constructs = this.constructs.map(construct => {
            construct.owner.membershipNames = construct.owner.organizationMemberships.map(membership => {
              return organizations.find(organization => organization.id === membership.organizationId).name;
            }).join(', ');
            return construct;
          });
        }

        this.constructs = this.constructs.map(construct => {
          construct.position = {
            x: construct.positionX,
            y: construct.positionY,
            z: construct.positionZ,
          };
          construct.size = {
            x: construct.sizeX,
            y: construct.sizeY,
            z: construct.sizeZ,
          };
          return construct;
        });
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/construct/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(res.data.message);
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('entity.common.constructs'));
      if ('organizationId' in this.$route.query) {
        const orgId = this.$route.query.organizationId;
        if (orgId !== null && orgId !== undefined) {
          this.organizationId = orgId;
        }
      }
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "type", title: this.__('entity.common.type'), type: "default" },
        { name: "ownerId", title: this.__('view.ingame.constructs.player_id_owner'), type: "default" },
        { name: "owner.name", title: this.__('view.ingame.constructs.player_name_owner'), type: "ref", disableSorting: true },
        {
          name: "owner.membershipNames",
          title: this.__('entity.common.organizations'),
          type: "ref",
          disableSorting: true
        },
        { name: "position", title: this.__('entity.common.position'), type: "vec3", disableSorting: true },
        { name: "size", title: this.__('entity.common.size'), type: "vec3", disableSorting: true },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('view.ingame.constructs.copy_coordinates'), icon: 'copy', event: 'copyCoordinates' },
        { title: this.__('view.ingame.constructs.show_construct_logs'), icon: 'change-log', event: 'showChangeLog' },
        {
          title: this.__('view.ingame.constructs.delete_construct'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
      this.refresh();
    }
  }
</script>
