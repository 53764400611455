/* (c) hdparm https://discordapp.com/channels/235480727251189760/504297604054777871/617662222440595476
   based on https://vvvv.org/blog/polar-spherical-and-geographic-coordinates

   ES6 conversion: WildChild85
*/

const rad2deg = 57.2957795130;

let planets = [
  {
    id: 1,
    name: "Madis",
    class: "hT",
    gravity: 3.5325,
    radius: 45000,
    pos: { x: 17465536, y: 22665536, z: -34464 }
  },
  {
    id: 2,
    name: "Alioth",
    class: "M",
    gravity: 9.891,
    radius: 126068,
    pos: { x: -8, y: -8, z: -126303 },
    standardGravitationalParameter: 155900000000
  },
  {
    id: 21,
    parentId: 2,
    name: "Alioth Moon 1",
    gravity: 2.355,
    radius: 30000,
    pos: { x: -564185.78, y: 233791, z: -167448 }
  },
  {
    id: 22,
    parentId: 2,
    name: "Alioth Moon 4",
    gravity: 2.380905,
    radius: 30330,
    pos: { x: -895203, y: 358389, z: -225602 }
  },
  {
    id: 3,
    name: "Thades",
    class: "T",
    gravity: 4.867,
    radius: 62000,
    pos: { x: 29165536, y: 10865536, z: 65536 }
  },
  {
    id: 30,
    parentId: 3,
    name: "Thades Moon 1",
    gravity: 1.099,
    radius: 14000,
    pos: { x: 29214403.49, y: 10907080.695, z: 433861.28 }
  },
  {
    id: 31,
    parentId: 3,
    name: "Thades Moon 2",
    gravity: 1.1775,
    radius: 15000,
    pos: { x: 29404194.34, y: 10432766.6, z: 19553.824 }
  },
  {
    id: 4,
    name: "Talemai",
    class: "M",
    gravity: 4.553,
    radius: 58000,
    pos: { x: -13234464, y: 55765536, z: 465536 }
  },
  {
    id: 5,
    name: "Feli",
    class: "M",
    gravity: 4.71,
    radius: 60000,
    pos: { x: -43534464, y: 22565536, z: -48934464 }
  },
  {
    id: 6,
    name: "Sicari",
    class: "M",
    gravity: 4.0035,
    radius: 51000,
    pos: { x: 52765536, y: 27165536, z: 52065536 }
  },
  {
    id: 7,
    name: "Sinnen",
    class: "hT",
    gravity: 4.3175,
    radius: 55000,
    pos: { x: 58665536, y: 29665536, z: 58165536 }
  },
  {
    id: 8,
    name: "Teoma",
    class: "M",
    gravity: 4.7885,
    radius: 61000,
    pos: { x: 80865536, y: 54665536, z: -934464 }
  },
  {
    id: 9,
    name: "Jago",
    class: "M",
    gravity: 4.9455,
    radius: 63000,
    pos: { x: -94134464, y: 12765536, z: -3634464 }
  },
  {
    id: 100,
    name: "Lacobus",
    class: "hP",
    gravity: 4.4745,
    radius: 57000,
    pos: { x: 98865536, y: -13534464, z: -934464 }
  },
  {
    id: 110,
    name: "Symeon",
    class: "hP",
    gravity: 3.8465,
    radius: 49000,
    pos: { x: 14165536, y: -85634464, z: -934464 }
  },
  {
    id: 120,
    name: "Ion",
    class: "hP",
    gravity: 3.5325,
    radius: 45000,
    pos: { x: 2865536, y: -99034464, z: -934464 }
  }
];

/**
 ---@param x number
 ---@param y number
 ---@param z number
 ---@return number, number longitude in radians, latitude in radians
 */

function gravityToLongLat({ x, y, z }) {
  x = -x;
  y = -y;
  z = -z;

  let g = Math.sqrt(x * x + y * y + z * z);

  let long = Math.atan2(y, x);
  let lat = Math.asin(z / g);

  return [long, lat];
}

function getPosString(worldPos) {
  let closestPlanet = null;
  let worldPosToClosestPlanet = null;
  let worldPosToClosestPlanetLen = null;

  planets.forEach(planet => {
    let worldPosToPlanet = {
      x: planet.pos.x - worldPos.x,
      y: planet.pos.y - worldPos.y,
      z: planet.pos.z - worldPos.z
    };
    const worldPosToPlanetXsquared = worldPosToPlanet.x * worldPosToPlanet.x;
    const worldPosToPlanetYsquared = worldPosToPlanet.y * worldPosToPlanet.y;
    const worldPosToPlanetZsquared = worldPosToPlanet.z * worldPosToPlanet.z;
    let worldPosToPlanetLen = Math.sqrt(worldPosToPlanetXsquared + worldPosToPlanetYsquared + worldPosToPlanetZsquared);

    if (!worldPosToClosestPlanetLen || worldPosToPlanetLen < worldPosToClosestPlanetLen) {
      closestPlanet = planet;
      worldPosToClosestPlanet = worldPosToPlanet;
      worldPosToClosestPlanetLen = worldPosToPlanetLen;
    }
  });

  let [longRad, latRad] = gravityToLongLat(worldPosToClosestPlanet);
  let longDeg = longRad * rad2deg;
  let latDeg = latRad * rad2deg;

// TODO: include altitude offset (core/altimeter altitude to ::pos/AGG altitude)
  let altitude = worldPosToClosestPlanetLen - closestPlanet.radius;

  return `::pos{0,${closestPlanet.id},${latDeg.toFixed(4)},${longDeg.toFixed(4)},${altitude.toFixed(4)}}`;
}

export default {
  getPosString: getPosString
};


