<template>
  <div class="construct-logs">
    <my-dialog :show="show" @close="close" :fullscreen="true">
      <div class="card">
        <div class="card__header">
          <div class="card__title">
            <template v-if="construct">[{{construct.id}}] {{construct.name}} -</template>
            {{__('entity.common.constructLogs')}}
          </div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="construct">
          <progress-linear v-if="loading"/>
          <div class="control-bar" :class="{'control-bar--disabled': loading}">
            <div class="control-bar__info">
              <div class="control-bar__info-title">{{__('common.total')}}:</div>
              <div class="control-bar__info-value">{{ pagination.total }}</div>
            </div>
            <div class="control-bar__buttons">
              <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
                <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                     alt="refresh"/>
              </div>
            </div>
          </div>
          <div class="scroll-horizontal-overflow">
            <pagination
                class="mt"
                :count-pages="pagination.pages"
                :total-visible="10"
                :page="pagination.page"
                :disabled="loading"
                @paginate="paginate"
            />
            <data-table
                :records="constructLogs"
                :fields="dataTableFields"
                :record-actions="recordActions"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :loading="loading"
                @sort="sort"
                @copyCoordinates="copyCooardinates"
            />
            <pagination
                class="mt"
                :count-pages="pagination.pages"
                :total-visible="10"
                :page="pagination.page"
                :disabled="loading"
                @paginate="paginate"
            />
            <input type="text" id="coordinates_constructLogs_clipboard" style="display: none"/>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import convertPos from '../../library/convertPos';
  import { paginationMixin } from "../../mixins/pagination";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin],
    components: {
      DataTable
    },
    data: () => ({
      constructId: null,
      show: false,
      loading: false,
      constructLogs: [],
      dataTableFields: [],
      recordActions: [],
      construct: null
    }),
    watch: {
      constructId() {
        if (this.constructId) {
          this.refresh();
        }
      }
    },
    methods: {
      close() {
        this.construct = null;
        this.constructId = null;
        this.show = null;
      },
      showDialog(constructId) {
        this.constructId = constructId;
        this.show = true;
      },
      copyCooardinates(constructLog) {
        const copyText = document.getElementById('coordinates_constructLogs_clipboard');
        const posString = convertPos.getPosString(constructLog.position);
        copyText.style.display = 'block';
        copyText.value = posString;
        copyText.select();
        document.execCommand("copy");
        copyText.style.display = 'none';
        this.showSuccessNotification(posString);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.constructId !== null) {
          params.constructIds = this.constructId;
        }
        apiSecured.get('/ingame/civilization/construct/logs', { params }).then(async (res) => {
          this.constructLogs = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.constructLogs_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        await apiSecured.get(`/ingame/civilization/construct/${this.constructId}`, {
          params: {},
        }).then((res) => {
          this.construct = res.data;
          this.showInfoNotification('Construct has been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });

        const ownerIds = this.constructLogs.map(construct => construct.ownerId);
        if (ownerIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/player/(${ownerIds.join(',')})`, {
            params: {
              fields: 'id,name,organizationMemberships'
            },
          }).then((res) => {
            this.constructLogs = this.constructLogs.map(record => {
              record.owner = res.data.find(resRecord => resRecord.id === record.ownerId);
              return record;
            });
            this.showInfoNotification('Owners have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        const orgIds = [];
        this.constructLogs.forEach(construct => {
          if ('organizationMemberships' in construct.owner) {
            orgIds.push(...construct.owner.organizationMemberships);
          }
        });
        let organizations = [];
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            organizations = res.data;
            this.showInfoNotification('Parents have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
          this.constructLogs = this.constructLogs.map(construct => {
            construct.owner.membershipNames = construct.owner.organizationMemberships.map(membership => {
              return organizations.find(organization => organization.id === membership).name;
            }).join(', ');
            return construct;
          });
        }

        this.constructLogs = this.constructLogs.map(construct => {
          construct.position = {
            x: construct.positionX,
            y: construct.positionY,
            z: construct.positionZ,
          };
          construct.size = {
            x: construct.sizeX,
            y: construct.sizeY,
            z: construct.sizeZ,
          };
          return construct;
        });
      },
    },
    created() {
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "constructId", title: 'constructId', type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "type", title: this.__('entity.common.type'), type: "default" },
        { name: "owner.id", title: this.__('view.ingame.constructs.player_id_owner'), type: "ref" },
        { name: "owner.name", title: this.__('view.ingame.constructs.player_name_owner'), type: "ref" },
        {
          name: "owner.membershipNames",
          title: this.__('entity.common.organizations'),
          type: "ref",
          disableSorting: true
        },
        { name: "position", title: this.__('entity.common.position'), type: "vec3" },
        { name: "size", title: this.__('entity.common.size'), type: "vec3" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('view.ingame.constructLogs.copy_coordinates'), icon: 'copy', event: 'copyCoordinates' },
      ];
    }
  }
</script>